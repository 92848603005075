import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './component/landing/landing.component';
import { WizardComponent } from './component/wizard/wizard.component';
const routes: Routes = [
   {path: 'results', component: LandingComponent, pathMatch: 'full'},
  {path: '', component: WizardComponent, pathMatch: 'full'},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
