import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({providedIn:'root'})
export class ExerciseService {
     origin: string = "https://app.themovementathlete.com";
    // origin: string = "http://127.0.0.1:8080";
    constructor(private http:HttpClient){
    }
    getExerciseData():Observable<any>{
        const headers = new HttpHeaders({'content-type':'application/json'});
         return this.http.get(this.origin +  '/api/quiz/skill_details',{headers:headers})
        // return this.http.get(this.origin + "/assets/skill_details.json");
    }
    getExerciseTypes():Observable<any>{
        const headers = new HttpHeaders({'content-type':'application/json'});
        return this.http.get(this.origin + '/api/moves/mobilities',{headers:headers})
        // return this.http.get(this.origin + "/assets/mobilities.json");
    }
    validateEmail(data):Observable<any>{
        const headers = new HttpHeaders({'Accept':'application/json'});
         return this.http.post(this.origin + '/api/quiz/email_exists/',data,{headers:headers})
        //return this.http.get(this.origin + "/assets/mobilities.json");
    }
    saveUser(data):Observable<any>{
        const headers = new HttpHeaders({'Accept':'application/json'});
         return this.http.post(this.origin + '/api/quiz/quiz_user/',data,{headers:headers})
        //return this.http.get(this.origin + "/assets/mobilities.json");
    }
    saveAnswer(data):Observable<any>{

        const headers = new HttpHeaders({'Accept':'application/json'});
         return this.http.post(this.origin + '/api/quiz/answers',data,{headers:headers})
        //return this.http.get(this.origin + "/assets/mobilities.json");
    }
}
