<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<link
    href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap"
    rel="stylesheet">
<link
    href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
    rel="stylesheet">

<div class="main-1">
    <header class="header-section">
        <div class="top-header-section">
            <div class="container">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-center">
                    <div class="sml-img timeline">
                        <div class="time-sec">
                            <img src="../../assets/images/logo.png">
                            <label class="time-sec">The Movement Athlete</label>
                        </div>
                    </div>
                </div>
                <div class="Question-1">
                     <p>Question {{question_num}} / 18</p>
                    <div class="line-div">
                        <div class="container">
                            <div class="progress" style="height:4px">
                                <div class="progress-bar" role="progressbar" [style.width.%]="width"
                                    style="background-color: #53BAD2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="card-body1">
        <div class="container">
            <div class="row1" *ngIf="isWizardSection == '1'">
                <div class="svg-bg bg-1-over">
                    <img class="svg-bg-right" src="assets/images/svg-bg-1.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="nameForm" (ngSubmit)="saveName()">
                        <div class="name-text">
                            <div class="container">
                                <p>Please enter your name</p>
                                <input type="text" formControlName="name" [datoAutoFocus]="name"
                                    [ngClass]="{ 'is-invalid': nameSubmitted && n.name.errors }" />
                                <div *ngIf="nameSubmitted && n.name.errors" class="invalid-feedback">
                                    <div *ngIf="n.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <!-- <div class="Previous-1"><a href="javascript:void(0);">< Previous</a></div> -->
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center m-auto">
                                        <div class="Next-1 new-next">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '2'">
                <div class="svg-bg">
                    <img class="svg-bg-left" src="assets/images/svg-bg-2.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="emailForm" (ngSubmit)="saveEmail(true,template,template2)">
                        <div class="name-text">
                            <div class="container">
                                <p>Please enter your email</p>
                                <input type="text" formControlName="email" [datoAutoFocus]="email"
                                    [ngClass]="{ 'is-invalid': emailSubmitted && e.email.errors }" />
                                <div *ngIf="emailSubmitted && e.email.errors" class="invalid-feedback">
                                    <div *ngIf="e.email.errors.required">Email is required</div>
                                    <div *ngIf="e.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromEmail()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button (click)="fbqTrackLead(); trckGA('quiz_funnel_2');" type="submit" id="email-next-btn">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ng-template #template>
                <div class="modal-header" id='Mymodal' [hidden]="true">
                    <h4 class="modal-title pull-left">Modal</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-5 col-sm-12 pr-0 pl-0">
                            <button type="button" class="close d-md-none d-sm-block" data-dismiss="modal">&times;</button>
                           <div class="popup-sec">
                              <img src="../../assets/images/Ellipse-196.svg">
                           </div>
                        </div>
                        <div class="col-md-7 col-sm-12">
                            <button type="button" class="close d-md-block d-none"  data-dismiss="modal">&times;</button>
                            <div class="popup-text">
                                <h2>HOLD ON! IT LOOKS LIKE YOU ALREADY HAVE A PREMIUM ACCOUNT WITH TMA.</h2>
                                <p>This survey is for new customers only and would delete all your current progress data.</p>
                                <p>Login to the TMA app to see your current progress or share this quiz with friends and family to see how do you stack up on the 9 fundamentals.</p>
                                <button type="button">SHARE</button>
                            </div>
                        </div>
                      </div>
                </div>
            </ng-template>

            <ng-template #template2>
                <div class="modal-header" id='Mymodal1' [hidden]="true">
                    <h4 class="modal-title pull-left">Modal</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div class="modal-body  pop-sectn1">
                    <div class="row">
                        <div class="col-md-5 col-sm-12 pr-0 pl-0">
                            <button type="button" class="close d-md-none d-sm-block" (click)="modalRef.hide()" data-dismiss="modal">&times;</button>
                           <div class="popup-sec1">
                              <img src="../../assets/images/Ellipse-197.svg">
                           </div>
                        </div>
                        <div class="col-md-7 col-sm-12">
                            <button type="button" class="close d-md-block d-none" (click)="modalRef.hide()" data-dismiss="modal">&times;</button>
                            <div class="popup-text">
                                <h2>OOPS! IT LOOKS LIKE YOU ALREADY HAVE AN ACCOUNT WITH TMA.</h2>
                                <p>If you continue,all your previous progress data will be deleted and replaced with the results of this survey.</p>
                                <p>Would you like to proceed?</p>
                                <button type="button" (click)="saveEmail(false,template,template2)">YES</button>
                                <button type="button" class="btn btn-primary grey">NO</button>
                            </div>
                        </div>
                      </div>
                </div>
            </ng-template>

            <div class="row" *ngIf="isWizardSection == '3'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-3.svg">
                </div>

                <div class="card-body card-body-box">
                    <form [formGroup]="passwordForm" (ngSubmit)="savePassword()">
                        <div class="name-text">
                            <div class="container create-password">
                                <div class="row">
                                    <div class="col-12">
                                        <p>Please create your password</p>
                                      <span class="create-password-summary" >
                                            So you can easily access your results and a personalised training plan
                                      </span>

                                        <input
                                            type="password"
                                            formControlName="password"
                                            [datoAutoFocus]="password"
                                            [ngClass]="{ 'is-invalid': passwordSubmitted && pw.password.errors }"
                                        />
                                        <div *ngIf="passwordSubmitted && pw.password.errors" class="invalid-feedback">
                                            <div *ngIf="pw.password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                  <p>&nbsp;</p>
                                    <div class="col-12">
                                        <p>Please confirm your password</p>
                                        <input
                                            type="password"
                                            formControlName="confirmPassword"
                                            [ngClass]="{ 'is-invalid': passwordSubmitted && pw.confirmPassword.errors }"
                                        />
                                        <div *ngIf="passwordSubmitted && pw.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="pw.confirmPassword.errors.required">Confirmation is required</div>
                                        </div>

                                        <div *ngIf="passwordSubmitted && passwordForm.errors" class="invalid-feedback">
                                            <div *ngIf="passwordForm.errors.mismatch">Passwords do not match</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromPassword()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="savePassword()">Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row" *ngIf="isWizardSection == '4'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-3.svg">
                </div>
                <div class="card-body gender-section card-body-box">
                    <form [formGroup]="genderForm">
                        <div class="name-text">
                            <div class="container">
                                <p>What`s your gender ?</p>
                            </div>
                        </div>
                        <div class="Next-btn">
                            <div class="container">
                                <div class="row">
                                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 align-self-center empty-col"></div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 align-self-center">
                                        <label class="">
                                            <div class="Female {{female}}">
                                                <div class="Female-img">
                                                    <img src="../../assets/images/white_female_icon.png">
                                                </div>
                                                <div class="Female-text"><span>Female</span></div>
                                            </div>
                                            <input type="radio" formControlName="gender"
                                                [ngClass]="{ 'is-invalid': genderSubmitted && g.gender.errors }"
                                                id="female" name="gender" (click)="FemaleBackground()" value="2"
                                                style="display:none">
                                        </label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 align-self-center">
                                        <label class="">
                                            <div class="Male {{male}}">
                                                <div class="Male-img">
                                                    <img src="../../assets/images/white_male_icon.png">
                                                </div>
                                                <div class="Male-text"><span>Male</span></div>
                                                <div class="col-2 col-sm-2 col-md-2 col-lg-2 align-self-center"></div>
                                            </div>
                                            <input type="radio" formControlName="gender"
                                                [ngClass]="{ 'is-invalid': genderSubmitted && g.gender.errors }"
                                                id="male" (click)="MaleBackground()" name="gender" value="1"
                                                style="display:none">
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="genderSubmitted && g.gender.errors" class="invalid-feedback">
                                    <div *ngIf="g.gender.errors.required">Gender is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromGender()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveGender()">Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '5'">
                <div class="svg-bg">
                    <img class="svg-bg-left" src="assets/images/svg-bg-5.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="ageForm" (ngSubmit)="saveAge()">
                        <div class="name-text">
                            <div class="container">
                                <p>How old are you?</p>
                                <input type="text" formControlName="age" [datoAutoFocus]="age"
                                    [ngClass]="{ 'is-invalid': ageSubmitted && a.age.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="ageSubmitted && a.age.errors" class="invalid-feedback">
                                    <div *ngIf="a.age.errors.required">Age is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromAge()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '6'">
                <div class="svg-bg">
                    <img class="svg-bg-left" src="assets/images/svg-bg-6.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="goalForm" (ngSubmit)="saveGoal()">
                        <div class="name-text">
                            <div class="container p-0">
                                <p>What's your main goal at the moment?</p><br>
                                <div class="loseweight-bx">
                                    <ul class="list-inline">
                                        <li class="list-inline-item  {{class0}}" id="li0">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(0)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }"
                                                id="loseWeight" name="goal" value="0">
                                            <label for="loseWeight">
                                                <figure><svg width="31" height="43" viewBox="0 0 31 43" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M28.5041 0.884427C21.891 1.46725 17.011 4.76816 14.1662 9.54014C12.2597 11.9301 8.45129 19.2268 7.06625 23.0465C4.55489 27.7965 2.36496 33.448 0.651153 38.5428C-0.396469 41.6583 4.30749 43.6909 5.36111 40.5613C6.6428 36.7496 8.19122 32.6285 9.94104 28.8041C11.6775 32.6905 13.442 36.5642 15.3365 40.3766C16.8049 43.3361 21.2235 40.7407 19.7604 37.7912C17.8099 33.8668 15.998 29.8797 14.2129 25.8793C14.2356 25.8019 14.2642 25.7266 14.2882 25.6499C14.5856 25.7346 14.9137 25.7606 15.2752 25.6919C18.0066 25.1791 20.738 24.6676 23.4714 24.1548C24.6391 23.9354 25.0865 22.6577 24.9025 21.6348C24.3503 18.5693 22.6038 16.4367 20.4299 14.4188C20.1072 13.3212 19.0309 12.3502 17.8365 11.3853C20.2579 7.4709 24.211 5.34632 29.3564 4.89286C31.9824 4.6628 31.1075 0.655698 28.5041 0.884427ZM18.5521 18.3085C19.2276 19.0114 19.8024 19.7509 20.2365 20.6072C18.9148 20.8552 17.5925 21.1026 16.2708 21.3507C16.9376 20.2771 17.7052 19.2635 18.5521 18.3085Z"
                                                            fill="white" />
                                                        <path
                                                            d="M24.2071 14.819C26.1498 14.819 27.7247 13.2441 27.7247 11.3013C27.7247 9.35859 26.1498 7.78369 24.2071 7.78369C22.2644 7.78369 20.6895 9.35859 20.6895 11.3013C20.6895 13.2441 22.2644 14.819 24.2071 14.819Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Lose weight
                                            </label>
                                        </li>

                                        <li class="list-inline-item {{class1}}" id="li1">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(1)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }"
                                                id="buildMuscle" name="goal" value="1">
                                            <label for="buildMuscle">
                                                <figure>
                                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M40.5839 15.2695L37.483 12.1686L39.8141 9.83836C40.0202 9.63248 40.1361 9.3535 40.1361 9.0617C40.1361 8.7699 40.0202 8.49092 39.8142 8.28496L36.6932 5.16396C36.2812 4.75197 35.5516 4.75197 35.1396 5.16396L32.8094 7.49504L29.708 4.39353C28.85 3.53549 27.4588 3.53549 26.6007 4.39353C25.7425 5.25156 25.7425 6.6428 26.6007 7.5009L37.4765 18.3769C38.3347 19.2349 39.7259 19.2349 40.5839 18.3769C41.442 17.5188 41.442 16.1276 40.5839 15.2695Z"
                                                            fill="white" />
                                                        <path
                                                            d="M18.3768 37.499L7.50098 26.623C6.6428 25.765 5.25171 25.765 4.39353 26.623C3.53549 27.4812 3.53549 28.8724 4.39353 29.7305L7.49497 32.8319L5.16843 35.1575C4.9624 35.3635 4.84653 35.6424 4.84653 35.9342C4.84653 36.226 4.9624 36.505 5.16843 36.711L8.28943 39.831C8.50396 40.0456 8.78506 40.1529 9.06617 40.1529C9.34727 40.1529 9.62838 40.0456 9.8429 39.831L12.1685 37.5054L15.2694 40.6064C16.1275 41.4645 17.5187 41.4645 18.3768 40.6064C19.235 39.7483 19.235 38.357 18.3768 37.499Z"
                                                            fill="white" />
                                                        <path
                                                            d="M23.4941 16.8232L16.8242 23.5158L21.4854 28.177L28.1553 21.4842L23.4941 16.8232Z"
                                                            fill="white" />
                                                        <path
                                                            d="M37.4766 21.4843L23.4932 7.50095C22.6352 6.64291 21.2439 6.64291 20.3858 7.50095C19.5276 8.35913 19.5276 9.75022 20.3858 10.6084L34.3692 24.5918C35.2272 25.4498 36.6184 25.4498 37.4766 24.5918C38.3347 23.7337 38.3347 22.3425 37.4766 21.4843Z"
                                                            fill="white" />
                                                        <path
                                                            d="M24.5918 34.3916L10.6084 20.4082C9.75033 19.5501 8.35909 19.5501 7.50106 20.4082C6.64288 21.2664 6.64288 22.6576 7.50106 23.5156L21.4844 37.499C22.3424 38.357 23.7337 38.357 24.5918 37.499C25.45 36.641 25.45 35.2497 24.5918 34.3916Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Build muscle
                                            </label>
                                        </li>
                                        <li class="list-inline-item {{class2}}" id="li2">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(2)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }"
                                                id="coolSkills" name="goal" value="2">
                                            <label for="coolSkills">
                                                <figure><svg width="45" height="45" viewBox="0 0 45 45" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M26.0339 32.6645C25.4702 32.6645 24.9146 32.5667 24.3821 32.3735L21.8858 31.4676L19.3907 32.3783C18.8561 32.5733 18.2976 32.6722 17.7308 32.6722C16.0113 32.6722 14.4076 31.7466 13.5455 30.2566L13.5293 30.2285L13.5374 40.7054C13.5374 41.6087 14.0346 42.4299 14.835 42.8485C15.1889 43.0336 15.5736 43.1252 15.9569 43.1252C16.4404 43.1252 16.9214 42.9795 17.3353 42.6923L21.8415 39.5641L26.3476 42.6923C27.0895 43.2073 28.0476 43.2672 28.848 42.8485C29.6483 42.4299 30.1455 41.6087 30.1455 40.7054V30.3722C29.2674 31.7899 27.7061 32.6645 26.0339 32.6645Z"
                                                            fill="white" />
                                                        <path
                                                            d="M35.3712 12.4561L32.6604 10.1858L32.0432 6.70396C31.838 5.54672 30.8368 4.70754 29.6618 4.70754C29.6611 4.70754 29.6602 4.70754 29.6595 4.70754L26.1235 4.71068L23.4128 2.44055C22.5113 1.68545 21.2036 1.68658 20.3034 2.44337L17.5968 4.71866L14.0608 4.72197C12.8849 4.7231 11.8839 5.56454 11.6808 6.72275L11.0699 10.2056L8.36331 12.481C7.46318 13.2377 7.23728 14.5256 7.82622 15.5434L9.59698 18.6041L8.98619 22.0868C8.78295 23.2451 9.43783 24.3769 10.5433 24.7782L13.8672 25.9845L15.6379 29.0452C16.0812 29.8112 16.8871 30.2534 17.7297 30.2534C18.0066 30.2534 18.2875 30.2056 18.5608 30.106L21.8824 28.8936L25.2062 30.0998C26.3115 30.5007 27.54 30.0526 28.127 29.0337L29.8922 25.9698L33.2139 24.7574C34.3185 24.3541 34.9713 23.2211 34.766 22.0632L34.1488 18.5815L35.914 15.5176C36.501 14.4987 36.2727 13.2111 35.3712 12.4561ZM21.8687 23.3433C18.0235 23.3433 14.8952 20.215 14.8952 16.3697C14.8952 12.5245 18.0235 9.39615 21.8687 9.39615C25.714 9.39615 28.8424 12.5245 28.8424 16.3697C28.8424 20.215 25.714 23.3433 21.8687 23.3433Z"
                                                            fill="white" />
                                                        <path
                                                            d="M26.0859 18.1163C27.0494 15.7918 25.9461 13.1264 23.6217 12.163C21.2972 11.1995 18.6319 12.3027 17.6684 14.6272C16.7049 16.9516 17.8082 19.617 20.1326 20.5805C22.457 21.544 25.1224 20.4407 26.0859 18.1163Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Learn cool skills
                                            </label>
                                        </li>
                                        <li class="list-inline-item {{class3}}" id="li3">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(3)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }"
                                                id="reallyStrong" name="goal" value="3">
                                            <label for="reallyStrong">
                                                <figure><svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M28.9001 2.15838C28.3981 1.21213 27.4045 0.625 26.3066 0.625H17.5003C15.8646 0.625 14.534 1.95562 14.534 3.59131V7.54639C14.534 9.18208 15.8646 10.5127 17.5003 10.5127H20.4665V6.55762H19.4778C18.9313 6.55762 18.489 6.11537 18.489 5.56885C18.489 5.02232 18.9313 4.58008 19.4778 4.58008H21.5212C22.0677 4.58008 22.51 5.02232 22.51 5.56885V10.5127H23.4987C24.0442 10.5127 24.4875 10.9559 24.4875 11.5015V19.3641C25.6537 19.6823 26.8003 20.0887 27.896 20.6365C28.3845 20.8808 28.5825 21.4747 28.3382 21.9632C28.0977 22.4456 27.5069 22.6515 27.0115 22.4055C22.3361 20.0668 16.6854 20.0668 12.01 22.4055C11.5214 22.6479 10.9266 22.4518 10.6833 21.9632C10.439 21.4747 10.6369 20.8808 11.1255 20.6365C12.6058 19.8964 14.1709 19.3897 15.7678 19.0685C13.5213 16.9177 10.4005 16.0915 7.31342 16.864C4.18012 17.6471 1.61042 20.1856 0.867933 23.3354C0.235461 26.0632 0.85152 28.8586 2.55771 31.0061C4.25909 33.1468 6.86446 34.375 9.59025 34.375C18.7064 34.375 26.2412 32.1779 33.8304 28.3371C34.1645 28.1691 34.375 27.8273 34.375 27.4536V23.1625C34.375 15.8259 32.4776 8.46858 28.9001 2.15838Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Get really strong
                                            </label>
                                        </li>
                                        <li class="list-inline-item {{class4}}" id="li4">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(4)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }"
                                                id="painFree" name="goal" value="4">
                                            <label for="painFree">
                                                <figure><svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M38.1128 25.8631C36.7242 21.6729 35.4844 17.4465 34.4064 13.1642C34.379 13.0558 34.3467 12.9543 34.3099 12.8566C34.1486 11.3203 33.2887 9.99022 31.5918 9.52319C30.1272 9.12154 28.1955 9.7592 27.3188 11.0862C26.1643 11.958 25.0328 12.8578 23.8553 13.6985C23.7824 13.7501 23.7089 13.8037 23.633 13.8585C24.4145 11.8845 25.214 9.91612 25.7639 7.86741C26.5927 4.77254 21.8065 3.45987 20.979 6.54851C19.9565 10.3576 18.0678 13.8878 17.0466 17.6975C16.6175 19.2991 17.8281 20.7774 19.439 20.8378C21.5836 20.9169 23.5962 19.9685 25.4743 18.7088C25.1928 19.4766 24.8441 20.2419 24.4113 21.0209C22.6783 21.8416 20.4515 22.1953 19.5467 22.1598C18.3985 22.1175 17.3536 21.5701 16.6804 20.6579C16.0309 19.7786 15.8217 18.6639 16.1069 17.6004C16.1094 17.5885 16.1137 17.5773 16.1175 17.5649C16.0097 17.6769 15.9101 17.7971 15.8198 17.9279C15.0838 19.0046 13.7705 21.2557 13.7705 21.2557C12.2287 23.6475 10.9129 26.1726 9.54478 28.6659C8.43324 30.6897 7.28684 32.693 5.95299 34.5841C4.06681 37.2624 8.46002 39.7881 10.3257 37.1416C12.0748 34.6595 13.5065 31.9899 14.9555 29.3278C15.6343 28.0837 16.3161 26.8414 17.0366 25.6215C17.0366 25.6215 17.9296 23.9383 18.4084 23.1275C19.7827 24.5255 21.0948 26.8849 22.1465 28.5395L22.1478 28.5725C20.2348 28.5333 18.3206 28.4897 16.4077 28.4529C16.0091 29.1597 15.6162 29.8715 15.2226 30.5938L14.9947 31.0129C14.5694 31.795 14.1373 32.584 13.6958 33.3748C16.5359 33.4234 19.3755 33.4906 22.2119 33.5417C22.7929 33.5523 23.372 33.5635 23.9524 33.5741C25.4102 33.6021 30.3663 30.006 31.1547 24.8991C31.3813 24.566 31.5115 24.2372 31.5625 23.9177C31.7182 23.6107 31.8645 23.3044 32.0028 22.9967C32.4312 24.3947 32.869 25.7902 33.3285 27.1814C34.3311 30.2052 39.1235 28.9094 38.1128 25.8631Z"
                                                            fill="white" />
                                                        <path
                                                            d="M8.33592 29.0825C8.46856 28.8403 8.60057 28.5974 8.73321 28.354C6.87131 28.3452 5.01003 28.3527 3.14938 28.3851C-0.0469893 28.4418 -0.0525938 33.4029 3.14938 33.3475C4.0523 33.3325 4.95586 33.3225 5.86003 33.3188C6.76109 31.909 7.57808 30.4649 8.33592 29.0825Z"
                                                            fill="white" />
                                                        <path
                                                            d="M33.0044 8.82903C35.2353 8.82903 37.0439 7.02048 37.0439 4.78951C37.0439 2.55855 35.2353 0.75 33.0044 0.75C30.7734 0.75 28.9648 2.55855 28.9648 4.78951C28.9648 7.02048 30.7734 8.82903 33.0044 8.82903Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Be mobile and pain free
                                            </label>
                                        </li>
                                        <li class="list-inline-item {{class5}}" id="li5">
                                            <input type="checkbox" formControlName="goal" (click)="checkedGoal(5)"
                                                [ngClass]="{ 'is-invalid': goalSubmitted && go.goal.errors }" id="fit"
                                                name="goal" value="5">
                                            <label for="fit">
                                                <figure><svg width="39" height="35" viewBox="0 0 39 35" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M28.4132 0.625C24.5709 0.625 21.2262 2.75089 19.5009 5.88484C17.7714 2.75089 14.4308 0.625 10.5885 0.625C6.90938 0.625 3.56877 2.77121 1.8067 5.94987C-0.289845 9.72607 0.428038 13.8884 2.10446 17.6768C2.10854 17.6849 2.11261 17.6931 2.11669 17.7012H10.4417L12.3506 14.2746C12.7381 13.5795 13.7455 13.5755 14.133 14.2705L17.6368 20.5141L23.0577 9.1448C23.4452 8.32778 24.6321 8.38468 24.938 9.23423L28.0217 17.6971H36.881C36.8851 17.689 36.8891 17.6809 36.8932 17.6727C38.5737 13.8843 39.2875 9.72196 37.1951 5.94581C35.433 2.77121 32.0883 0.625 28.4132 0.625Z"
                                                            fill="white" />
                                                        <path
                                                            d="M25.618 20.0955L23.7254 14.8926L18.7084 25.4163C18.3535 26.1643 17.3012 26.1967 16.8933 25.4773L13.2468 18.9777L12.5411 20.2419C12.3617 20.563 12.019 20.7662 11.6479 20.7662H3.95508C8.17265 26.526 16.359 32.4565 18.7982 34.1556C19.2183 34.4482 19.7812 34.4482 20.2013 34.1556C22.6405 32.4525 30.8268 26.5219 35.0444 20.7662H26.5807C26.1483 20.7662 25.7649 20.4979 25.618 20.0955Z"
                                                            fill="white" />
                                                    </svg>
                                                </figure>
                                                Just be healthy and fit
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="goalSubmitted && go.goal.errors" class="invalid-feedback">
                                    <div *ngIf="go.goal.errors.required">Goal is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromGoal()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '7'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="fitLevelForm">
                        <div class="name-text">
                            <div class="container">
                                <p>How do you estimate your fitness level?</p>
                                <div class="step-logo-section text-center">
                                    <figure>
                                        <svg width="202" height="101" viewBox="0 0 202 101" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M168.643 76.1151C183.972 80.8018 193.302 70.1853 196.942 62.6127C197.47 61.5156 196.624 60.3107 195.408 60.2807C178.683 59.8678 171.15 55.1971 153.966 45.7739L112.752 19.8931M168.643 76.1151L131.333 55.0645L135.978 76.1151C138.153 78.224 146.541 84.8332 153.966 86.3316C161.391 87.8299 167.739 79.6292 168.643 76.1151Z"
                                                stroke="#FBCD29" stroke-width="6.0119" />
                                            <path
                                                d="M33.1743 76.1145C18.1467 80.7089 8.88504 70.5969 5.09629 63.0629C4.49238 61.862 5.54541 60.5804 6.88015 60.7333C22.6511 62.5408 30.8885 56.3149 42.3402 49.8488L82.7809 24.0478M33.1743 76.1145L72.8059 51.7459L66.0958 76.1145C63.9211 78.2234 55.2762 84.8327 47.8511 86.331C40.4261 87.8294 34.0782 79.6286 33.1743 76.1145Z"
                                                stroke="#FBCD29" stroke-width="6.0119" />
                                            <path
                                                d="M55.9675 101C55.198 101 54.4765 100.711 53.8032 100.134C53.2261 99.5571 52.9375 98.8357 52.9375 97.97L53.2261 96.6714L87.9989 3.60715C88.6723 1.20238 90.3556 0 93.0489 0H108.776C111.277 0 112.96 1.20238 113.826 3.60715L148.599 96.6714C148.695 96.96 148.743 97.3929 148.743 97.97C148.743 98.8357 148.455 99.5571 147.878 100.134C147.3 100.711 146.627 101 145.858 101H133.305C132.054 101 131.092 100.711 130.419 100.134C129.746 99.5571 129.313 98.9319 129.12 98.2586L122.339 80.6557H79.4861L72.7046 98.2586C71.9351 100.086 70.5404 101 68.5204 101H55.9675ZM117.289 64.0629L100.84 19.4786L84.5361 64.0629H117.289Z"
                                                fill="#FBCD29" />
                                        </svg>

                                    </figure>
                                    <h2>{{fit_level_heading}}</h2>
                                    <h5>{{fit_level_txt}}</h5>
                                </div>
                                <div class="progressbar-steps">
                                    <ul class="progressbar">
                                        <li (click)="setFitLevel(10)" (change)="setFitLevel(10)" id="li1" class="{{liactive}}"
                                            [class.active]="isSetFitLevel == 10"><span class="span-dot"></span></li>
                                        <li (click)="setFitLevel(20)" (change)="setFitLevel(20)" id="li2" class="{{li2active}}" [class.active]="isSetFitLevel == 20">
                                            <span class="span-dot"></span></li>
                                        <li (click)="setFitLevel(30)" (change)="setFitLevel(30)" id="li3" class="{{li3active}}" [class.active]="isSetFitLevel == 30">
                                            <span class="span-dot"></span></li>
                                        <li (click)="setFitLevel(40)" (change)="setFitLevel(40)" id="li4" class="{{li4active}}" [class.active]="isSetFitLevel == 40">
                                            <span class="span-dot"></span></li>
                                        <li (click)="setFitLevel(50)" (change)="setFitLevel(50)" id="li5" class="{{li5active}}" [class.active]="isSetFitLevel == 50">
                                            <span class="span-dot"></span></li>
                                        <li (click)="setFitLevel(60)" (change)="setFitLevel(60)" id="li6"  [class.active]="isSetFitLevel == 60">
                                            <span class="span-dot"></span></li>
                                    </ul>
                                </div>
                                <div class="content-mw-700">
                                    <span class="float-left">Beginner</span>
                                    <span class="float-right">Advanced</span>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromFilLevel()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveFitLevel()">Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '8'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="workoutForm" (ngSubmit)="saveWorkOut()">
                        <div class="name-text">
                            <div class="container">
                                <p>How long do you want your workouts to be (in minutes)?</p>
                                <div class="">
                                    <input type="text" formControlName="workout" [datoAutoFocus]="workout"
                                        [ngClass]="{ 'is-invalid': workSubmitted && w.workout.errors }"
                                        (keypress)="onlyNumberKey($event)" >
                                </div>
                                <div *ngIf="workSubmitted && w.workout.errors" class="invalid-feedback">
                                    <div *ngIf="w.workout.errors.required">Workout is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromWorkOut()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isWizardSection == '9'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="areasForm" (ngSubmit)="saveExpAreas()">
                        <div class="name-text">
                            <div class="container">
                                <p>Do you experience pain in any of the following areas?</p>
                            </div>
                        </div>
                        <div class="box-imgs">
                            <div class="container">
                                <div class="img-box1">
                                    <ul class="list-inline">
                                        <ng-container *ngFor="let move of moves_data">
                                            <li class="list-inline-item" id="lstinline">
                                                <input type="checkbox" (click)="checkedValue(move.id)"
                                                    formControlName="painAreas" value="{{move.id}}" name="Ankle"
                                                    [ngClass]="{ 'is-invalid': painAreasSubmitted && areas.painAreas.errors }">
                                                <label id="lblicon">
                                                    <div class="ankle-box">
                                                        <img src="{{move.thumbnail}}">
                                                    </div>
                                                    <p [innerHTML]="move.name"></p>
                                                </label>
                                            </li>
                                        </ng-container>
                                    </ul>
                                    <!-- <div *ngIf="painAreasSubmitted && areas.painAreas.errors" class="invalid-feedback">
                                        <div *ngIf="areas.painAreas.errors.required">Pain-areas is required</div>
                                    </div> -->
                                </div>
                                <div class="footer">
                                    <div class="container">
                                        <div class="row m-md-0">
                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                                <div class="Previous-1">
                                                    <a href="javascript:void(0);" (click)="backFromAreas()">Previous</a>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                                <div class="Next-1">
                                                    <button type="submit">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Pushups primary exercise-->
            <div class="row" *ngIf="isWizardSection == '10'">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="pushupForm" (ngSubmit)="savePushups()">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>How many pushups do you think you can do in one go?</h1>
                                    <!-- <p>{{exercises[0]?.primary_exercise?.description}}</p> -->
                                </div>
                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[0]?.primary_exercise?.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <input type="text" formControlName="pushups" [datoAutoFocus]="pushups"
                                    [ngClass]="{ 'is-invalid': pushSubmitted && p.pushups.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="pushSubmitted && p.pushups.errors" class="invalid-feedback">
                                    <div *ngIf="p.pushups.errors.required">
                                        {{exercises[0]?.primary_exercise?.exercise_slug | titlecase}} is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromPushup()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Pushups secondary exercise-->
            <div class="row" *ngIf="normalpushupCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="secpushupForm" (ngSubmit)="savePushupsEx2()">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>How many knee pushups can you do?</h1>
                                    <!-- <p>{{exercises[0].secondary_exercise.description}}</p> -->
                                </div>
                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[0].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <input type="text" formControlName="sec_pushups" [datoAutoFocus]="sec_pushups"
                                    [ngClass]="{ 'is-invalid': kneeSubmitted && sPush.sec_pushups.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="kneeSubmitted && sPush.sec_pushups.errors" class="invalid-feedback">
                                    <div *ngIf="sPush.sec_pushups.errors.required">
                                        {{exercises[0].secondary_exercise.exercise_slug | titlecase}} is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromPushupEx2()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Pullups primary exercise-->
            <div class="row" *ngIf="normalpushupCount>=1 && normalpullupCount==undefined">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="pullupForm" (ngSubmit)="savePullups()">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>How many pullups can you do?</h1>
                                    <!-- <p>{{exercises[1].primary_exercise.description}}</p> -->
                                </div>

                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[1].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <input type="text" formControlName="pullups" [datoAutoFocus]="pullups"
                                    [ngClass]="{ 'is-invalid': pullSubmitted && pullup.pullups.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="pullSubmitted && pullup.pullups.errors" class="invalid-feedback">
                                    <div *ngIf="pullup.pullups.errors.required">
                                        {{exercises[1].primary_exercise.exercise_slug | titlecase}} is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromPullps()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Pullups secondary exercise-->
            <div class="row" *ngIf="normalpullupCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="secpullupForm" (ngSubmit)="savePullupsEx2()">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>How long can you hang on the bar (in seconds)? </h1>
                                    <!-- <p>{{exercises[1].secondary_exercise.description}}</p> -->
                                </div>

                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[1].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <input type="text" formControlName="sec_pullups" [datoAutoFocus]="sec_pullups"
                                    [ngClass]="{ 'is-invalid': pull2Submitted && sec.sec_pullups.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="pull2Submitted && sec.sec_pullups.errors" class="invalid-feedback">
                                    <div *ngIf="sec.sec_pullups.errors.required">
                                        {{exercises[1].secondary_exercise.exercise_slug | titlecase}} is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromPullupEx2()">Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Handstand primary exercise-->
            <div class="row" *ngIf="normalpullupCount>=1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="handstandForm">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>Can you hold a wall handstand? </h1>
                                    <!-- <p>{{exercises[2].primary_exercise.description}}</p> -->
                                </div>

                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[2].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                    <!-- <input type="dropdown" formControlName="handstand" class="form-control" [ngClass]="{ 'is-invalid': pushSubmitted && p.handstand.errors }" /> -->
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control"
                                        [ngClass]="{ 'is-invalid': handstandSubmitted && hand.handstand.errors }"
                                        style="max-width:500px; width:100%; margin: 0 auto;font-size: 20px;color: #000"
                                        formControlName="handstand">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="handstandSubmitted && hand.handstand.errors" class="invalid-feedback">
                                    <div *ngIf="hand.handstand.errors.required">
                                        {{exercises[2].primary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromHandstand()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveHandstands()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveHandstands(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveHandstands(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Handstand secondary exercise-->
            <div class="row" *ngIf="normalhandstandCount==0 && normalpullupCount==undefined">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="downwardDogForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you hold Downward Dog for 60 sec? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;"> -->
                                <!-- {{exercises[2].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[2].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <input type="dropdown" formControlName="handstand" class="form-control" [ngClass]="{ 'is-invalid': pushSubmitted && p.handstand.errors }" /> -->
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control"
                                        [ngClass]="{ 'is-invalid': downwardDogSubmitted && down.downwardDog.errors }"
                                        style="width:100%; max-width:500px; margin: 0 auto;font-size: 20px;color: #000"
                                        formControlName="downwardDog">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->

                                <!-- <div *ngIf="downwardDogSubmitted && down.downwardDog.errors" class="invalid-feedback">
                                    <div *ngIf="down.downwardDog.errors.required">
                                        {{exercises[2].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromHandstand()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveDowwards()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveDowwards(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveDowwards(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Squat primary exercise-->
            <div class="row" *ngIf="normalhandstandCount==1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="squatForm">
                        <div class="name-text">
                            <div class="container">
                                <div class="exercises-content text-center">
                                    <h1>Can you easily do 30 squats? </h1>
                                    <!-- <p>{{exercises[3].primary_exercise.description}}</p> -->
                                </div>
                                <div class="exercise_img" style="padding:0px 0px 100px 0px;">
                                    <img src="{{exercises[3].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                    <!-- <input type="dropdown" formControlName="handstand" class="form-control" [ngClass]="{ 'is-invalid': pushSubmitted && p.handstand.errors }" /> -->
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control"
                                        [ngClass]="{ 'is-invalid': squatSubmitted && s.squat.errors }"
                                        style="width:100% max-width:500px;margin: 0 auto;font-size: 20px;color: #000"
                                        formControlName="squat">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->

                                <!-- <div *ngIf="squatSubmitted && s.squat.errors" class="invalid-feedback">
                                    <div *ngIf="s.squat.errors.required">{{exercises[3].primary_exercise.exercise_slug}}
                                        is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromHandstand()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveSquat()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveSquat(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveSquat(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Squat secondary exercise-->
            <div class="row" *ngIf="normalsquatCount==0 && normalhandstandCount==undefined">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="boxsquatForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you do 30 box squats? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[3].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[3].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                    <!-- <input type="dropdown" formControlName="handstand" class="form-control" [ngClass]="{ 'is-invalid': pushSubmitted && p.handstand.errors }" /> -->
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control"
                                        [ngClass]="{ 'is-invalid': boxsquatSubmitted && box.BoxSquat.errors }"
                                        style="width:100%;max-width: 500px; margin: 0 auto;font-size: 20px;color: #000"
                                        formControlName="BoxSquat">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="boxsquatSubmitted && box.BoxSquat.errors" class="invalid-feedback">
                                    <div *ngIf="box.BoxSquat.errors.required">
                                        {{exercises[3].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromHandstand()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveSquat()">Next</button>
                    </div>
                 -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveSquat2(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveSquat2(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Dips primary exercise-->
            <div class="row" *ngIf="normalsquatCount==1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="dipsForm" (ngSubmit)="saveDips()">
                        <div class="name-text">
                            <div class="container">
                                <p>How many dips do you think you can do? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[4].primary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[4].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>

                                <input type="text" formControlName="dips" [datoAutoFocus]="dips"
                                    [ngClass]="{ 'is-invalid': dipsSubmitted && d.dips.errors }"
                                    (keypress)="onlyNumberKey($event)" />
                                <div *ngIf="dipsSubmitted && d.dips.errors" class="invalid-feedback">
                                    <div *ngIf="d.dips.errors.required">{{exercises[4].primary_exercise.exercise_slug | titlecase}}
                                        is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="backFromDips()">
                                                 Previous</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <button type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--Dips secondary exercise-->
            <div class="row" *ngIf="normaldipsCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="legsdipsForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you do 12 Legs Supported Dips? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[4].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[4].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="legsdips"
                                        [ngClass]="{ 'is-invalid': legsdipsSubmitted && legs.legsdips.errors }"
                                        style="width:100%; max-width:500px; margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div> -->
                                <!-- <div *ngIf="legsdipsSubmitted && legs.legsdips.errors" class="invalid-feedback">
                                    <div *ngIf="legs.legsdips.errors.required">
                                        {{exercises[4].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveLegsDips()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveLegsDips(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveLegsDips(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--OneArm primary exercise-->
            <div class="row" *ngIf="normaldipsCount >= 1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="OneArmForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you hold a one-arm plank for 30 sec? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[5].primary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[5].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="OneArm"
                                        [ngClass]="{ 'is-invalid': OneArmSubmitted && oneA.OneArm.errors }"
                                        style="width:100%; max-width:500px; margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="OneArmSubmitted && oneA.OneArm.errors" class="invalid-feedback">
                                    <div *ngIf="oneA.OneArm.errors.required">
                                        {{exercises[5].primary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveOneArm(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveOneArm(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--OneArm secondary exercise-->
            <div class="row" *ngIf="normalSecondArmCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="ArmPlankForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you hold two arms plank for 45 sec? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[5].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[5].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="ArmPlank"
                                        [ngClass]="{ 'is-invalid': ArmPlankSubmitted && arm.ArmPlank.errors }"
                                        style="width:100%; max-width:500px;margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->

                                <!-- <div *ngIf="ArmPlankSubmitted && arm.ArmPlank.errors" class="invalid-feedback">
                                    <div *ngIf="arm.ArmPlank.errors.required">
                                        {{exercises[5].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveOneArm2(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveOneArm2(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--V-shit hold primary exercise-->
            <div class="row" *ngIf="normalSecondArmCount==1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body exercise_block_9 card-body-box">
                    <form [formGroup]="AbsForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do a V-sit hold with a good form? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[6]?.primary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[6]?.primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="Abs"
                                        [ngClass]="{ 'is-invalid': AbsSubmitted && abs.Abs.errors }"
                                        style="width:100%; max-width:500px; margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->

                                <!-- <div *ngIf="AbsSubmitted && abs.Abs.errors" class="invalid-feedback">
                                    <div *ngIf="abs.Abs.errors.required">{{exercises[6].primary_exercise.exercise_slug}}
                                        is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveVShit(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveVShit(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--V-shit hold secondary exercise-->
            <div class="row" *ngIf="normalVShitCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="HollowHoldsForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do Hollow Hold Arms Up for 30 sec? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[6].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[6].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="HollowHolds"
                                        [ngClass]="{ 'is-invalid': HollowHoldsSubmitted && holds.HollowHolds.errors }"
                                        style="width:100%; max-width:500px;margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="HollowHoldsSubmitted && holds.HollowHolds.errors" class="invalid-feedback">
                                    <div *ngIf="holds.HollowHolds.errors.required">
                                        {{exercises[6].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-26align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveHollowHolds(0)"
                                                style="margin-top:50px;">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveHollowHolds(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Side primary exercise-->
            <div class="row" *ngIf="normalVShitCount==1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="SideEx1Form">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do 5 Side V Ups with a good form? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[7].primary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[7].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="SideEx1"
                                        [ngClass]="{ 'is-invalid': SideEx1Submitted && side.SideEx1.errors }"
                                        style="width:100%; max-width:500px; margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="SideEx1Submitted && side.SideEx1.errors" class="invalid-feedback">
                                    <div *ngIf="side.SideEx1.errors.required">
                                        {{exercises[7].primary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveSideEx1(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveSideEx1(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Side secondary exercise-->
            <div class="row" *ngIf="normalSideCount==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="sideLiftForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do 10 Side Lift with good form? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[7].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[7].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="sideLift"
                                        [ngClass]="{ 'is-invalid': sideLiftSubmitted && lift.sideLift.errors }"
                                        style="width:100%; max-width:500px;margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="sideLiftSubmitted && lift.sideLift.errors" class="invalid-feedback">
                                    <div *ngIf="lift.sideLift.errors.required">
                                        {{exercises[7].secondary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-md-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveSideLift(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveSideLift(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Back primary exercise-->
            <div class="row" *ngIf="normalSideCount==1">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="backRockForm">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do 20 Arch Rocks with a good form? </p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[8].primary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[8].primary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                                <!-- <div class="work-select-option mt-4 mt-md-0">
                                    <select class="form-control" formControlName="backRock"
                                        [ngClass]="{ 'is-invalid': backRockSubmitted && rock.backRock.errors }"
                                        style="width: 100%; max-width:500px;margin: 0 auto;font-size: 20px;color: #000">
                                        <option value="">--Select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </select>
                                </div> -->
                                <!-- <div *ngIf="backRockSubmitted && rock.backRock.errors" class="invalid-feedback">
                                    <div *ngIf="rock.backRock.errors.required">
                                        {{exercises[8].primary_exercise.exercise_slug}} is required</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="text-center">
                        <button class="btn btn-primary mr-1" type="button" (click)="backFromPullup()"> < Previous</button>
                        <button class="btn btn-secondary" type="button" (click)="saveOneArm()">Next</button>
                    </div> -->
                        <div class="footer">
                            <div class="container">
                                <div class="row m-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveRocks(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveRocks(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <!--Back secondary exercise-->
            <div class="row" *ngIf="normalBackCount2==0">
                <div class="svg-bg">
                    <img class="svg-bg-right" src="assets/images/svg-bg-7.svg">
                </div>
                <div class="card-body card-body-box">
                    <form [formGroup]="backRockForm2">
                        <div class="name-text">
                            <div class="container">
                                <p>Can you easily do 10 Arms by Ear Upper Body Lifts?</p>
                                <!-- <p style="font-size:30px !important; text-align: center;">
                                    {{exercises[8].secondary_exercise.description}}</p> -->
                                <div class="exercise_img" style="padding:0px 0px 200px 0px;">
                                    <img src="{{exercises[8].secondary_exercise.gif}}"
                                        style="padding:10px 0px 20px 0px;" />
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <div class="container">
                                <div class="row m-0">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Previous-1">
                                            <a href="javascript:void(0);" (click)="saveRocks2(0)">
                                                No</a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                        <div class="Next-1">
                                            <a href="javascript:void(0);" (click)="saveRocks2(1)">Yes</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" data-bs-backdrop="static" style="display: block; background-color: rgba(0, 0, 0, 0.32); z-index: 111111;" [style.display]="isEmailVerify ? 'block' : 'none'" *ngIf="isEmailVerify">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-body  pop-sectn1">

                <div class="row">
                    <div class="col-md-5 col-sm-12 pr-0 pl-0">
                        <button type="button" class="close d-md-none d-sm-block" (click)="closeCancelRec()" data-dismiss="modal">&times;</button>
                       <div class="popup-sec1">
                          <img src="../../assets/images/Ellipse-197.svg">
                       </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                        <button type="button" class="close d-md-block d-none" (click)="closeCancelRec()" data-dismiss="modal">&times;</button>
                        <div class="popup-text">
                            <h2>OOPS! IT LOOKS LIKE YOU ALREADY HAVE AN ACCOUNT WITH TMA.</h2>
                            <p>
          If you continue, all your previous account will be deleted and replaced with the results of this survey.</p>
          <p>                Would you like to proceed?</p>
          <button type="button" (click)="saveEmail(false,template,template2)">YES</button>
                             <button type="button" (click)="closeCancelRec()" class="btn btn-primary grey">NO</button>
                        </div>
                    </div>
                  </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" data-bs-backdrop="static" style="display: block; background-color: rgba(0, 0, 0, 0.32); z-index: 111111;" [style.display]="isEmailVerify2 ? 'block' : 'none'" *ngIf="isEmailVerify2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-body">

                <div class="row">
                    <div class="col-md-5 col-sm-12 pr-0 pl-0">
                        <button type="button" class="close d-md-none d-sm-block" (click)="close()" data-dismiss="modal">&times;</button>
                       <div class="popup-sec">
                          <img src="../../assets/images/Ellipse-196.svg">
                       </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                        <button type="button" class="close d-md-block d-none" (click)="close()" data-dismiss="modal">&times;</button>
                        <div class="popup-text">
                            <h2>HOLD ON! IT LOOKS LIKE YOU ALREADY HAVE A PREMIUM ACCOUNT WITH TMA.</h2>
                            <p>This survey is for new customers only and would delete all your current progress data.</p>
                            <p>Login to the TMA app to see your current progress or share this quiz with friends and family to see how do you stack up on the 9 fundamentals.</p>
                            <!-- <button type="button">SHARE</button>  -->
                        </div>
                    </div>
                  </div>

            </div>
        </div>
    </div>
</div>
<script>
  let inovar = document.querySelector("#email-next-btn")
  inovar.click(function() {
    console.log("dsds")
  });
</script>
<script>
  gtag('event', 'quiz_funnel_1', {'event_label': 'quiz_funnel_1'});
  console.log('OPEN QUIZ EVENT');
</script>
