import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExerciseService } from '../../service/exercise.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})

export class WizardComponent implements OnInit {
  ageForm: FormGroup;
  nameForm: FormGroup;
  goalForm: FormGroup;
  areasForm: FormGroup;
  emailForm: FormGroup;
  passwordForm: FormGroup;
  pushupForm: FormGroup;
  boxsquatForm: FormGroup;
  secpushupForm: FormGroup;
  pullupForm: FormGroup;
  secpullupForm: FormGroup;
  genderForm: FormGroup;
  workoutForm: FormGroup;
  fitLevelForm: FormGroup;
  dipsForm: FormGroup;
  legsdipsForm: FormGroup;
  OneArmForm: FormGroup;
  ArmPlankForm: FormGroup;
  VShitForm: FormGroup;
  HollowHoldsForm: FormGroup;
  SideEx1Form: FormGroup;
  class0: string;
  class1: string;
  class2: string;
  class3: string;
  class4: string;
  class5: string;
  liactive: string;
  li2active: string;
  li3active: string;
  li4active: string;
  li5active: string;
  male: string;
  female: string;
  status: number = 0;
  user_id: number;
  name = true;
  email = true;
  password = true;
  confirmPassword = true;
  age = true;
  workout = true;
  pushups = true;
  sec_pushups = true;
  pullups = true;
  sec_pullups = true;
  dips = true;
  ageSubmitted: boolean = false;
  nameSubmitted: boolean = false;
  workSubmitted: boolean = false;
  painAreasSubmitted: boolean = false;
  pushSubmitted: boolean = false;
  goalSubmitted: boolean = false;
  emailSubmitted: boolean = false;
  passwordSubmitted: boolean = false;
  genderSubmitted: boolean = false;
  boxsquatSubmitted: boolean = false;
  backRock2Submitted: boolean = false;
  backRockSubmitted: boolean = false;
  sideLiftSubmitted: boolean = false;
  SideEx1Submitted: boolean = false;
  HollowHoldsSubmitted: boolean = false;
  AbsSubmitted: boolean = false;
  ArmPlankSubmitted: boolean = false;
  OneArmSubmitted: boolean = false;
  legsdipsSubmitted: boolean = false;
  dipsSubmitted: boolean = false;
  squatSubmitted: boolean = false;
  downwardDogSubmitted: boolean = false;
  handstandSubmitted: boolean = false;
  pull2Submitted: boolean = false;
  isEmailVerify: boolean = false;
  isEmailVerify2: boolean = false;
  selectedItemsList: any = [];
  isPushups: boolean = false;
  width: number = 0;
  question_num: number = 1;
  isSetFitLevel: number = 10;
  isWizardSection: string = '1';
  fit_level_heading: string = "Not Fit At All";
  fit_level_txt: string = "I have trouble walking up the stairs";
  moves_data: any = [];
  exercises: any = [];
  normalpushupCount: number;
  normalpullupCount: number;
  normalhandstandCount: number;
  normalsquatCount: number;
  normalboxsquatCount: number;
  normaldipsCount: number;
  normalOneArmCount: number;
  normalSecondArmCount: number;
  normalAbsCount: number;
  normalSideCount: number;
  normalBackCount: number;
  normalBackCount2: number;
  normalVShitCount: number;
  pullSubmitted: boolean;
  kneeSubmitted: boolean;
  handstandForm: FormGroup;
  downwardDogForm: FormGroup;
  squatForm: FormGroup;
  sideLiftForm: FormGroup;
  backRockForm2: FormGroup;
  backRockForm: FormGroup;
  AbsForm: FormGroup;
  fullDetails: any = {
    // user: {},
    skills: []

  };
  withoutPlan: string;
  pain_areas = [];
  goals = [];
  skills: any = { primary_exercise: {}, secondary_exercise: {} }
  PullUpSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  HandstandSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  SquatSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  DipsSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  BodyLineSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  AbsSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  SideSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  BackSkills: any = { primary_exercise: {}, secondary_exercise: {} }
  modalRef: BsModalRef;
  constructor(private router: Router, private formBuilder: FormBuilder, private _exerciseService: ExerciseService, private modalService: BsModalService) {

  }
  ngOnInit(): void {
    window['dataLayer'] = window['dataLayer'] || {};

    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordMatchValidator });

    this.genderForm = this.formBuilder.group({
      gender: [, Validators.required],
    });
    this.ageForm = this.formBuilder.group({
      age: ['', Validators.required],
    });
    this.goalForm = this.formBuilder.group({
      goal: ['', Validators.required],
    });
    this.fitLevelForm = this.formBuilder.group({

    });
    this.workoutForm = this.formBuilder.group({
      workout: ['', Validators.required],
    });
    this.areasForm = this.formBuilder.group({
      painAreas: ['', Validators.required],
    });
    this.pushupForm = this.formBuilder.group({
      pushups: ['', Validators.required],
    });
    this.secpushupForm = this.formBuilder.group({
      sec_pushups: ['', Validators.required],
    });
    this.pullupForm = this.formBuilder.group({
      pullups: ['', Validators.required],
    });
    this.secpullupForm = this.formBuilder.group({
      sec_pullups: ['', Validators.required],
    });
    this.handstandForm = this.formBuilder.group({
      handstand: ['', Validators.required],
    });
    this.downwardDogForm = this.formBuilder.group({
      downwardDog: ['', Validators.required],
    });
    this.ArmPlankForm = this.formBuilder.group({
      ArmPlank: ['', Validators.required],
    });
    this.squatForm = this.formBuilder.group({
      squat: ['', Validators.required],
    });
    this.boxsquatForm = this.formBuilder.group({
      BoxSquat: ['', Validators.required],
    });
    this.dipsForm = this.formBuilder.group({
      dips: ['', Validators.required],
    });
    this.legsdipsForm = this.formBuilder.group({
      legsdips: ['', Validators.required],
    });
    this.OneArmForm = this.formBuilder.group({
      OneArm: ['', Validators.required],
    });
    this.AbsForm = this.formBuilder.group({
      Abs: ['', Validators.required],
    });
    this.HollowHoldsForm = this.formBuilder.group({
      HollowHolds: ['', Validators.required],
    });
    this.SideEx1Form = this.formBuilder.group({
      SideEx1: ['', Validators.required],
    });
    this.sideLiftForm = this.formBuilder.group({
      sideLift: ['', Validators.required],
    });
    this.backRockForm2 = this.formBuilder.group({
      backRock2: ['', Validators.required],
    });
    this.backRockForm = this.formBuilder.group({
      backRock: ['', Validators.required],
    });
    location.href = location.href + '#question-1';
  }

  /* ******* for question 1 - name functionality ******* */
  close() {
    this.isEmailVerify2 = false;
    $('body').removeClass('modal-open');
  }
  get n() { return this.nameForm.controls; }

  updateQuestionUrl(old_num, new_num) {
    let old_url = location.href;
    let new_url = old_url.replace('#question-' + old_num, '#question-' + new_num);

    location.href = new_url;
  }

  saveName() {
    this.nameSubmitted = true;
    if (this.nameForm.invalid) {
      return;
    }
    this.fullDetails.user_name = this.nameForm.value.name;
    this.width = 6;
    this.updateQuestionUrl(this.question_num, 2);
    this.question_num = 2;
    this.isWizardSection = '2';
    window.scroll(0, 0);
  }

  /* ******* for question 2 - email functionality ******* */

  get e() { return this.emailForm.controls; }

  saveEmail(validate, template: TemplateRef<any>, template2: TemplateRef<any>) {

    this.emailSubmitted = true;
    if (this.emailForm.invalid) {
      return;
    }
    if (validate == true) {
      this.validateEmail(this.emailForm.value.email, template, template2);
    }
    else {
      this.fullDetails.user_email = this.emailForm.value.email;

      this.width = 12;
      this.updateQuestionUrl(this.question_num, 4);
      this.question_num = 4;
      this.isWizardSection = '4'
      window.scroll(0, 0);
      $('body').removeClass('modal-open');
      $(".modal").css('display', 'none');
      $(".modal").removeClass('fade');
      $(".modal").removeClass('show');
    }
  }

  backFromEmail() {
    this.width = 10;
    this.updateQuestionUrl(this.question_num, 1);
    this.question_num = 1;
    this.isWizardSection = '1'
  }

  get pw() { return this.passwordForm.controls; }

  passwordMatchValidator(formGroup: FormGroup): null | { mismatch: true } {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  savePassword() {
    this.passwordSubmitted = true;

    if(this.passwordForm.invalid) {
      return;
    } else {
      let data = {
        'email': this.fullDetails.user_email,
        'user_name': this.fullDetails.user_name,
        'password': this.passwordForm.value.password
      }

      this._exerciseService.saveUser(data).subscribe(response => {
        if (response) {
          this.status = response.status;
          this.user_id = response.user_id;

          window['dataLayer'].push({'user_id': this.user_id});

          this.width = 12;
          this.updateQuestionUrl(this.question_num, 4);
          this.question_num = 4;
          this.isWizardSection = '4';
          window.scroll(0, 0);

          return this.status;
        }
      }, error => {
        console.log(error);
      })
    }
  }

  backFromPassword() {
    this.width = 10;
    this.updateQuestionUrl(this.question_num, 2);
    this.question_num = 2;
    this.isWizardSection = '2'
  }

  /* ******* for question 3 - gender functionality ******* */

  get g() { return this.genderForm.controls; }

  saveGender() {

    this.genderSubmitted = true;
    if (this.genderForm.invalid) {
      return;
    } else {

      let data = {
        "gender": this.genderForm.value
      }

      if (this.genderForm.value['gender'] == "1")
        this.fullDetails.gender = 1;
      else
        this.fullDetails.gender = 2;

      this.width = 18;
      this.updateQuestionUrl(this.question_num, 5);
      this.question_num = 5;
      this.isWizardSection = '5'
    }
    window.scroll(0, 0);
  }

  backFromGender() {
    this.width = 20;
    this.updateQuestionUrl(this.question_num, 2);
    this.question_num = 2;
    this.isWizardSection = '2'
    this.isEmailVerify = false;
  }

  /* ******* for question 4 - age functionality ******* */

  get a() { return this.ageForm.controls; }

  saveAge() {
    this.ageSubmitted = true;
    if (this.ageForm.invalid) {
      return;
    }
    this.fullDetails.age = this.ageForm.value.age;

    this.width = 24;
    this.updateQuestionUrl(this.question_num, 6);
    this.question_num = 6;
    this.isWizardSection = '6'
    window.scroll(0, 0);

  }

  backFromAge() {
    this.width = 30;
    this.updateQuestionUrl(this.question_num, 4);
    this.question_num = 4;
    this.isWizardSection = '4'
  }

  /* ******* for question 5 - goal functionality ******* */
  get go() { return this.goalForm.controls; }
  saveGoal() {
    if (this.goals.length < 1) {
      this.goalForm.reset();
    }
    this.goalSubmitted = true;
    if (this.goalForm.invalid) {
      return;
    }
    this.fullDetails.fitness_goals = this.goals;
    this.width = 30;
    this.updateQuestionUrl(this.question_num, 7);
    this.question_num = 7;
    this.isWizardSection = '7'
    this.fit_level_heading = "Not fit at all";
    this.fit_level_txt = "I have trouble walking up the stairs";
    window.scroll(0, 0);
  }

  backFromGoal() {
    this.width = 40;
    this.updateQuestionUrl(this.question_num, 5);
    this.question_num = 5;
    this.isWizardSection = '5'
  }

  /* ******* for question 6 - fit level functionality ******* */

  saveFitLevel() {
    this.width = 36;
    this.updateQuestionUrl(this.question_num, 8);
    this.question_num = 8;
    this.isWizardSection = '8'
    this.fullDetails.fitness_level = this.isSetFitLevel;
    window.scroll(0, 0);
  }

  setFitLevel(pvarId) {
    this.isSetFitLevel = pvarId;
    if (this.isSetFitLevel == 10) {
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      $('#li1').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li1').removeClass('active');
      $('#li2').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li3').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li4').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li5').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      this.fit_level_heading = "Not Fit At All";
      this.fit_level_txt = "I have trouble walikng up the stairs";
    } else if (this.isSetFitLevel == 20) {
      $('#li1').addClass('completeLevel');
      $('#li1').removeClass('active');
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      this.liactive = 'completeLevel';
      $('#li2').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li3').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li4').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li5').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li1').removeClass('yellow-dot blue-dot green-dot lightgreen-dot');
      this.fit_level_heading = "New To Exercising";
      this.fit_level_txt = "I never exercised regularly";
    } else if (this.isSetFitLevel == 30) {
      $('#li1').addClass('completeLevel yellow-dot');
      $('#li2').addClass('completeLevel yellow-dot');
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      this.liactive = 'completeLevel yellow-dot'
      this.li2active = 'completeLevel yellow-dot'
      $('#li2').removeClass('active');
      $('#li3').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li4').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li5').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li1').removeClass('blue-dot green-dot lightgreen-dot');
      $('#li2').removeClass('blue-dot green-dot lightgreen-dot');
      this.fit_level_heading = "Exercise From Time To Time";
      this.fit_level_txt = "I exercise every few weeks";
    } else if (this.isSetFitLevel == 40) {
      $('#li1').addClass('completeLevel blue-dot');
      $('#li2').addClass('completeLevel blue-dot');
      $('#li3').addClass('completeLevel blue-dot');
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      this.liactive = 'completeLevel blue-dot'
      this.li2active = 'completeLevel blue-dot'
      this.li3active = 'completeLevel blue-dot'
      $('#li3').removeClass('active');
      $('#li4').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li5').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li1').removeClass('yellow-dot green-dot lightgreen-dot');
      $('#li2').removeClass('yellow-dot green-dot lightgreen-dot');
      $('#li3').removeClass('yellow-dot green-dot lightgreen-dot');

      this.fit_level_heading = "Regular";
      this.fit_level_txt = "I exercise 1-3 times a week";
    } else if (this.isSetFitLevel == 50) {
      $('#li1').addClass('completeLevel lightgreen-dot');
      $('#li2').addClass('completeLevel lightgreen-dot');
      $('#li3').addClass('completeLevel lightgreen-dot');
      $('#li4').addClass('completeLevel lightgreen-dot');
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      this.liactive = 'completeLevel lightgreen-dot'
      this.li2active = 'completeLevel lightgreen-dot'
      this.li3active = 'completeLevel lightgreen-dot'
      this.li4active = 'completeLevel lightgreen-dot'
      $('#li4').removeClass('active');
      $('#li5').removeClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      $('#li1').removeClass('yellow-dot blue-dot green-dot');
      $('#li2').removeClass('yellow-dot blue-dot green-dot');
      $('#li3').removeClass('yellow-dot blue-dot green-dot');
      $('#li4').removeClass('yellow-dot blue-dot green-dot');
      this.fit_level_heading = "Very Fit";
      this.fit_level_txt = "I exercise 4-5 times a week";
    } else {
      this.fit_level_heading = "Experienced Athlete";
      this.fit_level_txt = "I train almost every day";
      $('#li1').addClass('completeLevel green-dot');
      $('#li2').addClass('completeLevel green-dot');
      $('#li3').addClass('completeLevel green-dot');
      $('#li4').addClass('completeLevel green-dot');
      $('#li5').addClass('completeLevel yellow-dot blue-dot green-dot lightgreen-dot');
      this.liactive = ''
      this.li2active = ''
      this.li3active = ''
      this.li4active = ''
      this.li5active = ''
      this.liactive = 'completeLevel green-dot'
      this.li2active = 'completeLevel green-dot'
      this.li3active = 'completeLevel green-dot'
      this.li4active = 'completeLevel green-dot'
      this.li5active = 'completeLevel yellow-dot blue-dot green-dot lightgreen-dot'
      $('#li5').removeClass('active');
    }

    this.fullDetails.fitness_level = this.isSetFitLevel;
  }

  backFromFilLevel() {

    this.width = 50;
    this.updateQuestionUrl(this.question_num, 6);
    this.question_num = 6;
    this.isWizardSection = '6'
    this.class0 = '';
    this.class1 = '';
    this.class2 = '';
    this.class3 = '';
    this.class4 = '';
    this.class5 = '';
    if (this.goals.length != 0) {
      for (var i = 0; i <= this.goals.length; i++) {
        if (this.goals[i] == "0") {
          this.class0 = 'active';

        }
        if (this.goals[i] == "1") {
          this.class1 = 'active';

        }
        if (this.goals[i] == "2") {
          this.class2 = 'active';

        }
        if (this.goals[i] == "3") {
          this.class3 = 'active';

        }
        if (this.goals[i] == "4") {
          this.class4 = 'active';

        }
        if (this.goals[i] == "5") {
          this.class5 = 'active';

        }

      }
    }

  }

  /* ******* for question 7 - workout functionality ******* */

  get w() { return this.workoutForm.controls; }

  saveWorkOut() {
    this.workSubmitted = true;
    if (this.workoutForm.invalid) {
      return;
    } else {
      let data = {
        "workout": this.workoutForm.value.workout
      }
      this.getExerciseTypes();
      this.width = 38;
      this.updateQuestionUrl(this.question_num, 9);
      this.question_num = 9;
      this.isWizardSection = '9'
      this.fullDetails.workout_duration = this.workoutForm.value.workout;
    }

    window.scroll(0, 0);
    console.log(this.fullDetails);
  }

  backFromWorkOut() {
    this.width = 60;
    this.updateQuestionUrl(this.question_num, 7);
    this.question_num = 7;
    this.isWizardSection = '7'
  }

  /* ******* for question 8 - experience areas functionality ******* */

  getExerciseTypes() {
    this.moves_data = [];
    this._exerciseService.getExerciseTypes().subscribe(response => {
      if (response) {
        this.moves_data = response
      }
    }, error => {
      console.log(error);
    })
  }

  validateEmail(email, template, template2) {

    let data = {'email': email, 'user_name': this.fullDetails.user_name}

    this._exerciseService.validateEmail(data).subscribe(response => {
      if (response) {
        this.status = response.status;

        if(response.user_id !== null ) {
          this.user_id = response.user_id;

          window['dataLayer'].push({'user_id': this.user_id});
        }

        if (this.status === 1) {
          this.isEmailVerify = true;
          $('body').addClass('modal-open');
        }
        else if (this.status === 2) {
          this.isEmailVerify = true;
          $('body').addClass('modal-open');
        }
        else if (this.status === 3) {
          this.isEmailVerify2 = true;
          $('body').addClass('modal-open');
        }
        else if (this.status === 4) {
          this.isEmailVerify = true;
          $('body').addClass('modal-open');
        } else {
          this.fullDetails.user_email = this.emailForm.value.email;
          this.width = 12;
          if(response.user_id !== null ) {
            this.updateQuestionUrl(this.question_num, 4);
            this.question_num = 4;
            this.isWizardSection = '4';
          } else {
            this.updateQuestionUrl(this.question_num, 3);
            this.question_num = 3;
            this.isWizardSection = '3';
          }

          window.scroll(0, 0);
        }
        return this.status;
      }
    }, error => {
      console.log(error);
    })
  }

  closeCancelRec() {
    this.isEmailVerify = false;
    $('body').removeClass('modal-open');
  }
  saveExpAreas() {

    this.painAreasSubmitted = true;
    // if (this.areasForm.invalid) {
    //   return;
    // }
    // else {
    this.width = 48;
    this.updateQuestionUrl(this.question_num, 10);
    this.question_num = 10;
    this.isWizardSection = '10'
    this._exerciseService.getExerciseData().subscribe(response => {
      if (this.exercises.length == 0) {
        this.exercises.push(response["pushup-fundamental"]);
        this.exercises.push(response["pullup-fundamental"]);
        this.exercises.push(response["handstand-fundamental"]);
        this.exercises.push(response["squat-fundamental"]);
        this.exercises.push(response["dips-fundamental"]);
        this.exercises.push(response["bodyline-fundamental"]);
        this.exercises.push(response["ab-fundamental"]);
        this.exercises.push(response["side-fundamental"]);
        this.exercises.push(response["back-fundamental"]);
      }


    });


    this.fullDetails.pain_areas = this.pain_areas;
    // window.scroll(0, 0);
    console.log("exercises", this.exercises);
    // }
  }
  checkedValue(id) {

    if (this.pain_areas.length != 0) {
      if (this.pain_areas.indexOf(id) !== -1) {
        for (var i = 0; i <= this.pain_areas.length; i++) {

          if (this.pain_areas[i] == id) {
            this.pain_areas.splice(i, 1);
            break;
          } else {

          }
        }
      } else {
        this.pain_areas.push(id);
      }
    } else {
      this.pain_areas.push(id);
    }

    console.log(this.pain_areas);
  }
  checkedGoal(id) {

    if (this.goals.length != 0) {
      if (this.goals.indexOf(id) !== -1) {
        for (var i = 0; i <= this.goals.length; i++) {

          if (this.goals[i] == id) {
            this.goals.splice(i, 1);
            if (id == "0") {
              $('#li0').removeClass('active');
            }
            if (id == "1") {
              $('#li1').removeClass('active');
            }
            if (id == "2") {
              $('#li2').removeClass('active');
            }
            if (id == "3") {
              $('#li3').removeClass('active');
            }
            if (id == "4") {
              $('#li4').removeClass('active');
            }
            if (id == "5") {
              $('#li5').removeClass('active');
            }
            break;
          }
        }
      }
      else {
        this.goals.push(id);
        if (id == "0") {
          $('#li0').addClass('active');
        }
        if (id == "1") {
          $('#li1').addClass('active');
        }
        if (id == "2") {
          $('#li2').addClass('active');
        }
        if (id == "3") {
          $('#li3').addClass('active');
        }
        if (id == "4") {
          $('#li4').addClass('active');
        }
        if (id == "5") {
          $('#li5').addClass('active');
        }
      }
    } else {
      this.goals.push(id);
      if (id == "0") {
        $('#li0').addClass('active');
      }
      if (id == "1") {
        $('#li1').addClass('active');
      }
      if (id == "2") {
        $('#li2').addClass('active');
      }
      if (id == "3") {
        $('#li3').addClass('active');
      }
      if (id == "4") {
        $('#li4').addClass('active');
      }
      if (id == "5") {
        $('#li5').addClass('active');
      }
    }


  }
  backFromAreas() {
    this.width = 70;
    this.updateQuestionUrl(this.question_num, 8);
    this.question_num = 8;
    this.isWizardSection = '8'
  }
  /* ******* for question 9 - pushup functionality ******* */

  get p() { return this.pushupForm.controls; }
  get side() { return this.SideEx1Form.controls; }
  get holds() { return this.HollowHoldsForm.controls; }
  get abs() { return this.AbsForm.controls; }
  get arm() { return this.ArmPlankForm.controls; }
  get oneA() { return this.OneArmForm.controls; }
  get legs() { return this.legsdipsForm.controls; }
  get d() { return this.dipsForm.controls; }
  get box() { return this.boxsquatForm.controls; }
  get s() { return this.squatForm.controls; }
  get down() { return this.downwardDogForm.controls; }
  get hand() { return this.handstandForm.controls; }
  get sec() { return this.secpullupForm.controls; }
  get pullup() { return this.pullupForm.controls; }
  get sPush() { return this.secpushupForm.controls; }
  get lift() { return this.sideLiftForm.controls; }
  get rock() { return this.backRockForm.controls; }
  get rock2() { return this.backRockForm2.controls; }
  get areas() { return this.areasForm.controls; }
  savePushups() {
    this.width = 54;
    this.pushSubmitted = true;
    if (this.pushupForm.invalid) {
      return;
    } else {
      let data = {
        "pushups": this.pushupForm.value.pushups
      }
      // this.width = 100;
      // this.question_num = '5';
      // this.isWizardSection = '5'

      this.normalpushupCount = this.pushupForm.value.pushups;

      this.skills.skill_slug = "pushup-fundamental";
      this.skills.primary_exercise.exercise_slug = this.exercises[0].primary_exercise.exercise_slug
      this.skills.primary_exercise.answer_type = 1;
      this.skills.primary_exercise.answer_number = this.pushupForm.value.pushups;
      this.fullDetails.skills.push(this.skills);
      if (this.pushupForm.value.pushups >= 1) {
        this.updateQuestionUrl(this.question_num, this.question_num + 1);
        this.question_num = this.question_num + 1;
        //this.skills.secondary_exercise = [];
        delete this.skills['secondary_exercise'];
        //this.skills.secondary_exercise.
      }
      // this.question_num = this.question_num + 1;
      this.isWizardSection = '11';
      window.scroll(0, 0);
    }
  }
  savePushupsEx2() {

    this.kneeSubmitted = true;
    if (this.secpushupForm.invalid) {
      return;
    } else {
      let data = {
        "pushups": this.pushupForm.value.pushups
      }

      this.normalpullupCount = undefined;
      // this.normalpushupCount = this.secpushupForm.value.sec_pushups;
      this.normalpushupCount = 1;
      this.skills.secondary_exercise.exercise_slug = this.exercises[0].secondary_exercise.exercise_slug
      this.skills.secondary_exercise.answer_type = 1;
      this.skills.secondary_exercise.answer_number = this.secpushupForm.value.sec_pushups;
      this.fullDetails.skills.pop();
      this.fullDetails.skills.push(this.skills);
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.isWizardSection = '11';
      window.scroll(0, 0);

    }
  }
  backFromPushup() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num -1);
    this.question_num = this.question_num - 1;
    this.isWizardSection = '9'
    this.fullDetails.skills.splice(0, 1);
    window.scroll(0, 0);

  }
  backFromPushupEx2() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.isWizardSection = '10'
    this.normalpushupCount = undefined;

    this.fullDetails.skills.splice(0, 1);
    window.scroll(0, 0);
  }
  backFromPullupEx2() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(1, 1);
    this.normalpushupCount = 2;
    this.normalpullupCount = undefined;
    window.scroll(0, 0);
  }
  backFromPullps() {

    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    // this.isWizardSection = '9'
    this.fullDetails.skills.splice(1, 1);
    if (this.pushupForm.value.pushups >= 1) {
      this.isWizardSection = '10'
      this.normalpushupCount = undefined;
      this.normalpullupCount = undefined;
    } else {
      this.normalpushupCount = 0;
      this.isWizardSection = undefined;

      this.normalpullupCount = undefined;
    }
    window.scroll(0, 0);
  }
  savePullups() {


    this.pullSubmitted = true;
    if (this.pullupForm.invalid) {
      return;
    } else {
      let data = {
        "pushups": this.pushupForm.value.pushups
      }
      this.width = 60;
      // this.question_num = '5';
      // this.isWizardSection = '5'

      this.normalpullupCount = this.pullupForm.value.pullups;
      // this.skills.primary_exercise =[];

      this.PullUpSkills.skill_slug = "pullup-fundamental";
      this.PullUpSkills.primary_exercise.exercise_slug = this.exercises[1].primary_exercise.exercise_slug;
      this.PullUpSkills.primary_exercise.answer_type = 1;
      this.PullUpSkills.primary_exercise.answer_number = this.pullupForm.value.pullups;

      this.fullDetails.skills.push(this.PullUpSkills);
      if (this.pullupForm.value.pullups >= 1) {
        this.updateQuestionUrl(this.question_num, this.question_num + 1);
        this.question_num = this.question_num + 1;
        delete this.PullUpSkills['secondary_exercise'];
      }
      //this.question_num = this.question_num + 1;
      console.log("--", this.fullDetails.skills[0]);

      console.log("full details", this.fullDetails);
      window.scroll(0, 0);
    }
  }
  savePullupsEx2() {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.pull2Submitted = true;
    if (this.secpullupForm.invalid) {
      return;
    } else {
      let data = {
        "pushups": this.pushupForm.value.pushups
      }

      this.PullUpSkills.secondary_exercise.exercise_slug = this.exercises[1].secondary_exercise.exercise_slug
      this.PullUpSkills.secondary_exercise.answer_type = 1;
      this.PullUpSkills.secondary_exercise.answer_number = this.secpullupForm.value.sec_pullups;
      this.fullDetails.skills.pop();

      this.fullDetails.skills.push(this.PullUpSkills);
      this.normalpullupCount = 1;
      window.scroll(0, 0);
    }
  }
  // backFromPullup() {
  //   this.width = 80;
  //   this.question_num = this.question_num - 1;
  //   this.isWizardSection = '8'
  //   // this.fullDetails.skills.pop();
  //   window.scroll(0, 0);
  // }

  backFromHandstand() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.normalhandstandCount = undefined;

    // this.isWizardSection = '9'
    this.fullDetails.skills.splice(1, 1);
    if (this.pullupForm.value.pullups >= 1) {
      // this.isWizardSection ='9'
      this.normalpushupCount = 2;
      this.normalpullupCount = undefined;
    } else {
      this.normalpullupCount = 0;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  backFromHandstandEx2() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(2, 1);
    this.normalpullupCount = 2;
  }
  backFromSquat() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(2, 1);

    // this.isWizardSection = '9'

    if (this.handstandForm.value.handstand >= 1) {
      // this.isWizardSection ='9'

      this.normalpullupCount = 2;
      this.normalhandstandCount = 0;
    } else {
      this.normalhandstandCount = 0;
      this.normalpullupCount = undefined;
      this.isWizardSection = undefined;

    }

    window.scroll(0, 0);
  }
  backFromSquat2() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    // this.isWizardSection = '9'
    this.fullDetails.skills.splice(3, 1);

    if (this.squatForm.value.squat >= 1) {
      // this.isWizardSection ='9'
      this.normalhandstandCount = 1;
    } else {
      this.normalhandstandCount = 1;
      this.normalpullupCount = undefined;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }

  saveHandstands(val) {

    // this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.handstandSubmitted = true;
    this.handstandForm.value.handstand = val;
    // if (this.handstandForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    this.width = 66;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalpullupCount = undefined;
    this.normalpushupCount = undefined;
    this.normalhandstandCount = this.handstandForm.value.handstand;
    this.HandstandSkills.skill_slug = "handstand-fundamental";
    this.HandstandSkills.primary_exercise.exercise_slug = this.exercises[2].primary_exercise.exercise_slug
    this.HandstandSkills.primary_exercise.answer_type = 2;
    // this.HandstandSkills.primary_exercise.answer_number = this.handstandForm.value.handstand;
    if (this.handstandForm.value.handstand == 0) {
      this.HandstandSkills.primary_exercise.answer_boolean = "False";

    }
    if (this.handstandForm.value.handstand == 1) {
      this.HandstandSkills.primary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.push(this.HandstandSkills);
    if (this.handstandForm.value.handstand >= 1) {
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.HandstandSkills.secondary_exercise = [];
      delete this.HandstandSkills['secondary_exercise'];
    }
    window.scroll(0, 0);
    //}
  }
  saveDowwards(val) {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.downwardDogSubmitted = true;
    this.pushupForm.value.pushups = val;
    // if (this.downwardDogForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    // this.width = 100;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalpullupCount = undefined;
    this.normalpushupCount = undefined;
    // this.normalhandstandCount = this.downwardDogForm.value.downwardDog;
    this.normalhandstandCount = 1;
    this.HandstandSkills.secondary_exercise.exercise_slug = this.exercises[2].secondary_exercise.exercise_slug
    this.HandstandSkills.secondary_exercise.answer_type = 2;
    // this.HandstandSkills.secondary_exercise.answer_number = this.downwardDogForm.value.downwardDog;
    if (this.downwardDogForm.value.downwardDog == 0) {
      this.HandstandSkills.secondary_exercise.answer_boolean = "False";

    }
    if (this.downwardDogForm.value.downwardDog == 1) {
      this.HandstandSkills.secondary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.HandstandSkills);
    window.scroll(0, 0);
    //}
  }
  saveSquat(val) {

    this.isWizardSection = '11';
    this.squatSubmitted = true;
    this.squatForm.value.squat = val;
    // if (this.squatForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    this.width = 72;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalpullupCount = undefined;
    this.normalhandstandCount = undefined;
    this.normalsquatCount = this.squatForm.value.squat;
    this.SquatSkills.skill_slug = "squat-fundamental";
    this.SquatSkills.primary_exercise.exercise_slug = this.exercises[3].primary_exercise.exercise_slug
    this.SquatSkills.primary_exercise.answer_type = 2; //// if textbox -- 1
    // this.SquatSkills.primary_exercise.answer_number = this.squatForm.value.squat;
    if (this.squatForm.value.squat == 0) {
      this.SquatSkills.primary_exercise.answer_boolean = "False";

    }
    if (this.squatForm.value.squat == 1) {
      this.SquatSkills.primary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.push(this.SquatSkills);
    if (this.squatForm.value.squat >= 1) {
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.SquatSkills.secondary_exercise = [];
      delete this.SquatSkills['secondary_exercise'];

    }
    window.scroll(0, 0);
    //}
  }
  saveSquat2(val) {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.boxsquatSubmitted = true;
    this.boxsquatForm.value.BoxSquat = val;
    // if (this.boxsquatForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    this.normalpullupCount = undefined;
    this.normalhandstandCount = undefined;
    // this.normalsquatCount = this.boxsquatForm.value.BoxSquat;
    this.normalsquatCount = 1;
    this.SquatSkills.secondary_exercise.exercise_slug = this.exercises[3].secondary_exercise.exercise_slug
    this.SquatSkills.secondary_exercise.answer_type = 2;
    // this.SquatSkills.secondary_exercise.answer_number = this.boxsquatForm.value.BoxSquat;
    if (this.boxsquatForm.value.BoxSquat == 0) {
      this.SquatSkills.secondary_exercise.answer_boolean = "False";

    }
    if (this.boxsquatForm.value.BoxSquat == 1) {
      this.SquatSkills.secondary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.SquatSkills);
    window.scroll(0, 0);
    //}
  }
  backFromDips() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(3, 1);

    if (this.squatForm.value.squat >= 1) {
      // this.isWizardSection ='9'
      this.normalsquatCount = undefined;
      this.normalhandstandCount = 1;
    } else {
      this.normalsquatCount = 0;
      this.normalhandstandCount = undefined;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  backFromLegDips() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(4, 1);

    this.normalsquatCount = 1;
    this.normalhandstandCount = undefined;
    this.isWizardSection = undefined;
    this.normaldipsCount = undefined;
    window.scroll(0, 0);
  }
  backFromOneArm() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(4, 1);

    if (this.dipsForm.value.dips >= 1) {
      // this.isWizardSection ='9'
      this.normalsquatCount = 1;
    } else {
      this.normaldipsCount = 0;
      this.normalhandstandCount = undefined;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  backFromSecondArm() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(5, 1);

    this.normaldipsCount = 1;
    this.normalSecondArmCount = undefined;
    this.isWizardSection = undefined;
    window.scroll(0, 0);
  }
  backFromVShit() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(5, 1);
    if (this.OneArmForm.value.OneArm >= 1) {
      // this.isWizardSection ='9'
      this.normalSecondArmCount = undefined;
      this.normaldipsCount = 1;
    } else {
      this.normalSecondArmCount = 0;
      this.normaldipsCount = undefined;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  backFromHollow() {
    this.width = 80;
    // this.question_num = this.question_num - 1;

    this.fullDetails.skills.splice(6, 1);
    this.normalSecondArmCount = 1;
    this.normalVShitCount = undefined;
    this.isWizardSection = undefined;
    window.scroll(0, 0);
  }
  backFrom5Side() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(6, 1);
    if (this.AbsForm.value.Abs >= 1) {
      // this.isWizardSection ='9'
      this.normalVShitCount = undefined;
      this.normalSecondArmCount = 1;
    } else {
      this.normalVShitCount = 0;
      this.normalSecondArmCount = undefined;
      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  backFrom10Side() {
    this.width = 80;
    // this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(7, 1);
    this.normalVShitCount = 1;
    this.normalSideCount = undefined;
    this.isWizardSection = undefined;
    window.scroll(0, 0);
  }
  saveDips() {

    this.isWizardSection = '11';
    this.dipsSubmitted = true;
    if (this.dipsForm.invalid) {
      return;
    } else {
      let data = {
        "pushups": this.pushupForm.value.pushups
      }

      this.width = 78;
      // this.question_num = '5';
      // this.isWizardSection = '5'
      this.normalsquatCount = undefined;
      this.normalhandstandCount = undefined;
      // this.normaldipsCount = undefined;
      this.normaldipsCount = this.dipsForm.value.dips;
      this.DipsSkills.skill_slug = "dips-fundamental";
      this.DipsSkills.primary_exercise.exercise_slug = this.exercises[4].primary_exercise.exercise_slug
      this.DipsSkills.primary_exercise.answer_type = 1;
      this.DipsSkills.primary_exercise.answer_number = this.dipsForm.value.dips;
      this.fullDetails.skills.push(this.DipsSkills);
      if (this.dipsForm.value.dips >= 1) {
        this.updateQuestionUrl(this.question_num, this.question_num + 1);
        this.question_num = this.question_num + 1;
        this.DipsSkills.secondary_exercise = [];
        delete this.DipsSkills['secondary_exercise'];

      }
    }
    window.scroll(0, 0);
  }
  saveLegsDips(val) {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.legsdipsSubmitted = true;
    this.legsdipsForm.value.legsdips = val;
    // if (this.legsdipsForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    // this.width = 100;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalsquatCount = undefined;
    this.normaldipsCount = undefined;
    // this.normaldipsCount = this.legsdipsForm.value.legsdips;
    this.normaldipsCount = 1;
    this.DipsSkills.secondary_exercise.exercise_slug = this.exercises[4].secondary_exercise.exercise_slug
    this.DipsSkills.secondary_exercise.answer_type = 2;
    if (this.legsdipsForm.value.legsdips == 0) {
      this.DipsSkills.secondary_exercise.answer_boolean = "False";

    }
    if (this.legsdipsForm.value.legsdips == 1) {
      this.DipsSkills.secondary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.DipsSkills);
    console.log(this.fullDetails);
    //}
    window.scroll(0, 0);
  }
  saveOneArm(val) {

    this.isWizardSection = '11';
    this.OneArmSubmitted = true;
    this.OneArmForm.value.OneArm = val;
    // if (this.OneArmForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    this.width = 84;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalsquatCount = undefined;
    this.normaldipsCount = undefined;
    this.normalSecondArmCount = this.OneArmForm.value.OneArm;
    this.BodyLineSkills.skill_slug = "bodyline-fundamental";
    this.BodyLineSkills.primary_exercise.exercise_slug = this.exercises[5].primary_exercise.exercise_slug
    this.BodyLineSkills.primary_exercise.answer_type = 2; //// if textbox -- 1
    // this.BodyLineSkills.primary_exercise.answer_number = this.OneArmForm.value.OneArm;
    if (this.OneArmForm.value.OneArm == 0) {
      this.BodyLineSkills.primary_exercise.answer_boolean = "False";

    }
    if (this.OneArmForm.value.OneArm == 1) {
      this.BodyLineSkills.primary_exercise.answer_boolean = "True";

    }
    this.fullDetails.skills.push(this.BodyLineSkills);
    if (this.OneArmForm.value.OneArm >= 1) {
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.BodyLineSkills.secondary_exercise = [];
      delete this.BodyLineSkills['secondary_exercise'];

      //}
    }
    window.scroll(0, 0);
  }
  saveOneArm2(val) {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.ArmPlankSubmitted = true;
    this.ArmPlankForm.value.ArmPlank = val;
    // if (this.ArmPlankForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    // this.width = 100;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalsquatCount = undefined;
    this.normaldipsCount = undefined;
    // this.normalOneArmCount = this.ArmPlankForm.value.ArmPlank;
    this.normalSecondArmCount = 1;
    window.scroll(0, 0);
    this.BodyLineSkills.secondary_exercise.exercise_slug = this.exercises[5].secondary_exercise.exercise_slug
    this.BodyLineSkills.secondary_exercise.answer_type = 2;
    // this.BodyLineSkills.secondary_exercise.answer_number = this.secpushupForm.value.sec_pushups;
    if (this.ArmPlankForm.value.ArmPlank == 0) {
      this.BodyLineSkills.secondary_exercise.answer_boolean = "False";

    }
    if (this.ArmPlankForm.value.ArmPlank == 1) {
      this.BodyLineSkills.secondary_exercise.answer_boolean = "True";

    }
    this.BodyLineSkills.skills.pop();
    this.fullDetails.skills.push(this.BodyLineSkills);
    //}
  }
  saveVShit(val) {

    //this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.AbsSubmitted = true;
    // if (this.AbsForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }
    this.AbsForm.value.Abs = val;
    this.width = 90;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalSecondArmCount = undefined;
    this.normalVShitCount = this.AbsForm.value.Abs;
    this.AbsSkills.skill_slug = "ab-fundamental";
    this.AbsSkills.primary_exercise.exercise_slug = this.exercises[6].primary_exercise.exercise_slug
    this.AbsSkills.primary_exercise.answer_type = 2;
    //this.AbsSkills.primary_exercise.answer_number = this.handstandForm.value.handstand;
    if (this.AbsForm.value.Abs == 0) {
      this.AbsSkills.primary_exercise.answer_boolean = "False";
    }
    if (this.AbsForm.value.Abs == 1) {
      this.AbsSkills.primary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.push(this.AbsSkills);
    if (this.AbsForm.value.Abs >= 1) {
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.AbsSkills.secondary_exercise = [];
      delete this.AbsSkills['secondary_exercise'];

    }
    //}
    window.scroll(0, 0);
  }
  saveHollowHolds(val) {
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;
    this.isWizardSection = '11';
    this.HollowHoldsSubmitted = true;
    this.HollowHoldsForm.value.HollowHolds = val;
    // if (this.HollowHoldsForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    // this.width = 100;
    // this.question_num = '5';
    // this.isWizardSection = '5'

    // this.normalVShitCount = this.HollowHoldsForm.value.HollowHolds;
    this.normalVShitCount = 1;
    this.AbsSkills.secondary_exercise.exercise_slug = this.exercises[6].secondary_exercise.exercise_slug
    this.AbsSkills.secondary_exercise.answer_type = 2;
    //this.AbsSkills.secondary_exercise.answer_number = this.secpushupForm.value.sec_pushups;
    if (this.HollowHoldsForm.value.HollowHolds == 0) {
      this.AbsSkills.secondary_exercise.answer_boolean = "False";
    }
    if (this.HollowHoldsForm.value.HollowHolds == 1) {
      this.AbsSkills.secondary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.AbsSkills);
    //}
    window.scroll(0, 0);
  }
  saveSideEx1(val) {
    this.SideEx1Submitted = true;
    this.SideEx1Form.value.SideEx1 = val;
    // if (this.SideEx1Form.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }

    this.width = 96;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalVShitCount = undefined;
    this.normalSideCount = this.SideEx1Form.value.SideEx1;
    this.SideSkills.skill_slug = "side-fundamental";
    this.SideSkills.primary_exercise.exercise_slug = this.exercises[7].primary_exercise.exercise_slug
    this.SideSkills.primary_exercise.answer_type = 2;
    //this.AbsSkills.primary_exercise.answer_number = this.handstandForm.value.handstand;
    if (this.SideEx1Form.value.SideEx1 == 0) {
      this.SideSkills.primary_exercise.answer_boolean = "False";
    }
    if (this.SideEx1Form.value.SideEx1 == 1) {
      this.SideSkills.primary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.push(this.SideSkills);
    if (this.SideEx1Form.value.SideEx1 >= 1) {
      this.updateQuestionUrl(this.question_num, this.question_num + 1);
      this.question_num = this.question_num + 1;
      this.SideSkills.secondary_exercise = [];
      delete this.SideSkills['secondary_exercise'];

    }
    //}
    //this.question_num = this.question_num + 1;

    window.scroll(0, 0);
  }
  saveSideLift(val) {
    this.sideLiftSubmitted = true;

    this.sideLiftForm.value.sideLift = val;

    this.normalVShitCount = undefined;
    this.normalSideCount = 1;
    // this.normalBackCount = this.sideLiftForm.value.sideLift;
    this.normalBackCount = 1;
    this.SideSkills.secondary_exercise.exercise_slug = this.exercises[7].secondary_exercise.exercise_slug
    this.SideSkills.secondary_exercise.answer_type = 2;
    //this.AbsSkills.secondary_exercise.answer_number = this.secpushupForm.value.sec_pushups;
    if (this.sideLiftForm.value.sideLift == 0) {
      this.SideSkills.secondary_exercise.answer_boolean = "False";
    }
    if (this.sideLiftForm.value.sideLift == 1) {
      this.SideSkills.secondary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.SideSkills);
    //}
    this.updateQuestionUrl(this.question_num, this.question_num + 1);
    this.question_num = this.question_num + 1;

    window.scroll(0, 0);
  }

  saveRocks(val) {
    this.backRockSubmitted = true;
    // if (this.backRockForm.invalid) {
    //   return;
    // } else {
    //   let data = {
    //     "pushups": this.pushupForm.value.pushups
    //   }
    this.backRockForm.value.backRock = val;
    this.width = 100;
    // this.question_num = '5';
    // this.isWizardSection = '5'
    this.normalVShitCount = undefined;
    this.normalSideCount = undefined;
    this.normalBackCount2 = undefined;
    this.normalBackCount2 = this.backRockForm.value.backRock;

    this.BackSkills.skill_slug = "back-fundamental";
    this.BackSkills.primary_exercise.exercise_slug = this.exercises[8].primary_exercise.exercise_slug
    this.BackSkills.primary_exercise.answer_type = 2;
    //this.AbsSkills.primary_exercise.answer_number = this.handstandForm.value.handstand;
    if (this.backRockForm.value.backRock == 0) {
      this.BackSkills.primary_exercise.answer_boolean = "False";
    }
    if (this.backRockForm.value.backRock == 1) {
      this.BackSkills.primary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.push(this.BackSkills);
    if (this.backRockForm.value.backRock >= 1) {
      this.BackSkills.secondary_exercise = [];
      delete this.BackSkills['secondary_exercise'];
      console.log(this.fullDetails);
      this.saveAnswer();
      this.router.navigate(['/results']);

    }

    // }
    //this.question_num = this.question_num + 1;
    window.scroll(0, 0);

  }
  backFromRocks() {
    this.width = 80;
    this.updateQuestionUrl(this.question_num, this.question_num - 1);
    this.question_num = this.question_num - 1;
    this.fullDetails.skills.splice(6, 1);
    if (this.SideEx1Form.value.SideEx1 >= 1) {
      // this.isWizardSection ='9'
      this.normalSideCount = undefined;
      this.normalVShitCount = 1;
    } else {
      this.normalSideCount = 0;

      this.isWizardSection = undefined;

    }
    window.scroll(0, 0);
  }
  saveRocks2(val) {
    this.isWizardSection = '';
    this.normalBackCount2 = 1;
    this.backRockForm2.value.backRock2 = val;

    this.BackSkills.secondary_exercise.exercise_slug = this.exercises[8].secondary_exercise.exercise_slug
    this.BackSkills.secondary_exercise.answer_type = 2;
    //this.AbsSkills.secondary_exercise.answer_number = this.secpushupForm.value.sec_pushups;
    if (this.backRockForm2.value.backRock2 == 0) {
      this.BackSkills.secondary_exercise.answer_boolean = "False";
    }
    if (this.backRockForm2.value.backRock2 == 1) {
      this.BackSkills.secondary_exercise.answer_boolean = "True";
    }
    this.fullDetails.skills.pop();
    this.fullDetails.skills.push(this.BackSkills);
    //}
    // this.question_num = this.question_num + 1;
    this.saveAnswer();
    this.router.navigate(['/results']);
    window.scroll(0, 0);
  }
  saveAnswer() {

    let data = {
      'answer': this.fullDetails
    }

    this._exerciseService.saveAnswer(this.fullDetails).subscribe(response => {
      if (response) {
        this.status = response.status;
      }
    }, error => {
      console.log(error);
    })
  }
  FemaleBackground() {
    $('.Male').removeClass('blue')
    $('.Female').addClass('blue')
    this.female = 'blue';
    this.male = '';
  }
  MaleBackground() {
    $('.Male').addClass('blue')
    this.male = 'blue';
    $('.Female').removeClass('blue')
    this.female = '';
  }

  onlyNumberKey(evt) {
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    }
    return true;
  }

  fbqTrackLead() {
    console.log('track Lead');
    // @ts-ignore
    fbq('track', 'Lead');
  }

  // tslint:disable-next-line:variable-name
  is_tracked: boolean = false;

  trckGA(eventName: string) {
    if (this.is_tracked === false) {
      console.log('track GA', eventName);
      // @ts-ignore
      gtag('event', eventName, {event_label: eventName});
      this.is_tracked = true;
    } else {
      return;
    }
  }
}
