import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './component/landing/landing.component';
import { WizardComponent } from './component/wizard/wizard.component';
// tslint:disable-next-line:import-spacing
import {HttpClientModule} from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AutoFocusDirective } from './directives/auto-focus.directive';
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    WizardComponent,
    AutoFocusDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
