import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  modalRef: BsModalRef;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder, private modalService: BsModalService
  ) {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  ngOnInit(): void {
  }
  close() {

  }
  get basic() { return this.registerForm.controls; }
  popup(template: TemplateRef<any>)
  {
    this.modalService.show(template, Object.assign({}, { class: 'gray modal-dialog-centered' }));
  }
  onSubmit(form) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      let data = {
        "email": form.email
      }
      this.router.navigate(['/wizard']);
    }
  }

  trckGA(eventName: string) {
    console.log('track GA', eventName);
    // @ts-ignore
    gtag('event', eventName, {event_label: eventName});
  }
}
